import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TeamMember from "../components/team-member";

function About() {
  const { heroImage, avatar1, avatar2, avatar3 } = useStaticQuery(query);
  const bgImage = convertToBgImage(getImage(heroImage));

  return (
    <Layout>
      <SEO title="About" />

      <BackgroundImage
        Tag="section"
        {...bgImage}
        preserveStackingContext
        className="-mt-32 h-screen object-cover"
      />

      <section className="py-10 px-4">
        <div className="container max-w-5xl mx-auto">
          <div className="-mt-44 p-6 bg-white shadow-lg rounded-lg text-gray-800">
            <div className="text-center">
              <h1 className="my-5 font-extrabold text-4xl">Who We Are</h1>
              <p className="font-bold text-xl">What Makes Hayaan Different</p>
            </div>

            <div className="mt-10 text-sm">
              <p className="mt-2">
              Hayaan SACCO strives to be the one stop shop for sharia compliant financial solutions for the Muslim Community and other ethically conscious individuals. Not only does Hayaan SACCO provide Ethical Financial solutions but also offers highly innovative and cutting-edge solutions in interest free Credit Access, Social and Business Finance, Savings as well as slew of ethical high return investments. As a sharia compliant SACCO, Hayaan offers interest free loans and other sharia compliant products such as.
              </p>
              <p className="mt-2">
              As a sharia compliant SACCO, Hayaan offers interest free loans and other sharia compliant products such as

- Interest Free Loans to members. Member access instant loans with 0% interest and No Hidden Charges
- Business Financing: This is done partnership basis through sharing of profit/loss
- Sharia Compliant Investments: Professionally run and high return investments in Halal activities ranging from real estate, livestock, horticulture and other industries
- Livestock Wealth Management: Hayaan Intends to offer innovative livestock wealth management solutions to pastoralist who suffer perennial droughts. The idea is to enable Pastoralists to develop alternative assets over time from their livestock, whilst still practicing their traditional lifestyle
- Halal Financial Literacy Training in: Savings, Pensions, Investment and general financial management
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 px-4">
        <div className="container max-w-5xl mx-auto">
          <h2 className="font-bold text-2xl text-center">The Team</h2>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-10">
            <TeamMember 
              name="MOHAMED SHARIF ADAN" 
              title="Co-Founder & CTO" 
              description="BSc in Computer Science, first-class honours graduate from the University of Nairobi various post-graduate qualifications in Project Management, System Integration, Leadership and Management, Database Management and Product Development" 
              avatar={getImage(avatar1)} 
            />
            <TeamMember 
              name="NAME USERNAME" 
              title="Co-Founder & CTO" 
              description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et dolore." 
              avatar={getImage(avatar2)} 
            />
            <TeamMember 
              name="NAME USERNAME" 
              title="Co-Founder & CTO" 
              description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et dolore." 
              avatar={getImage(avatar3)} 
            />
          </div>
        </div>
      </section>

      {/* <section className="py-10 px-4">
        <div className="container max-w-5xl mx-auto"></div>
      </section> */}
      
    </Layout>
  );
}

const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "beach-front.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    avatar1: file(relativePath: { eq: "Mohamed-Sharif.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    avatar2: file(relativePath: { eq: "avatar.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    avatar3: file(relativePath: { eq: "avatar.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default About;
