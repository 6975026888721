import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

interface Props {
  name: string;
  title: string;
  description: string;
  avatar: any;
}

function TeamMember({ name, title, description, avatar }: Props) {
  return (
    <div className="p-4 rounded-lg bg-gray-50">
      <div className="p-4 rounded-lg bg-white flex justify-center">
        <GatsbyImage
          className="object-contain mx-auto"
          alt="team member avatar"
          image={avatar}          
        />
      </div>
      <div className="mt-5 text-center text-xs">
        <h3 className="font-extrabold text-md">{name}</h3>
        <div className="mt-2 text-[#F7931E]">{title}</div>
        <div className="mt-3 text-[#68A7CC]">{description}</div>
      </div>
    </div>
  );
}

export default TeamMember;
